import React from 'react'

import { GetGlobalPagesResponse } from '@viewlio/types/src/api/juulio'
import { decamelize } from 'humps'
import { useIntl } from 'react-intl'

import { LocalizedLink } from 'components/common/LocalizedLink'
import { TranslatedMessage } from 'components/common/TranslatedMessage'

import styles from './StoresByRegion.module.scss'

type Props = {
  isGlobalSupport: boolean
  storesByRegion: GetGlobalPagesResponse['storesByRegion']
}

export const StoresByRegion: React.FC<Props> = ({
  isGlobalSupport,
  storesByRegion,
}) => {
  const intl = useIntl()
  return (
    <>
      {
        Object
          .entries(storesByRegion)
          .map(([region, stores]) => stores.length ? (
            <div key={region} className={styles.regionRow}>
              <div className={styles.regionLabel}>
                <TranslatedMessage
                  id={`global_pages.regions.${decamelize(region)}`}
                />
              </div>

              <div className={styles.regionStores}>
                {stores.map(store => {
                  const href = isGlobalSupport ?
                    store.supportUrl : store.storeUrl

                  return (
                    <LocalizedLink
                      className={styles.store}
                      href={href}
                      key={store.code}
                    >
                      <img
                        alt={`Flag of ${intl.formatMessage({ id: `juulio.stores.${store.code}` })}`}
                        className={styles.flag}
                        src={`/images/icons/flags/${store.countryIso}.svg`}
                      />
                      <div className={styles.storeLabelWrapper}>
                        <div className={styles.primaryStoreLabel}>
                          <TranslatedMessage id={`juulio.stores.${store.code}`} />
                        </div>
                        <div className={styles.secondaryStoreLabel}>
                          {{
                            false: (
                              <TranslatedMessage
                                id={`juulio.stores.local_language.${store.code}`}
                              />
                            ),
                            true: (
                              <TranslatedMessage
                                id={`global_pages.global_support.support_text.${store.code}`}
                              />
                            ),
                          }[String(isGlobalSupport)]}
                        </div>
                      </div>
                    </LocalizedLink>
                  )
                })}
              </div>
            </div>
          ) : null)
      }
      {
        isGlobalSupport && (
          <div className={styles.regionRow}>
            <div className={styles.regionLabel}>
              <TranslatedMessage id='juulio.duty_free.name' />
            </div>

            <div className={styles.regionStores}>
              <LocalizedLink
                className={styles.store}
                href='/documents/duty_free.pdf'
              >
                <img
                  alt='Duty free icon'
                  className={styles.flag}
                  src='/images/icons/icon-duty-free.svg'
                />
                <div className={styles.storeLabelWrapper}>
                  <div className={styles.primaryStoreLabel}>
                    <TranslatedMessage id='juulio.duty_free.heading' />
                  </div>
                  <div className={styles.secondaryStoreLabel}>
                    <TranslatedMessage id='juulio.duty_free.subheading' />
                  </div>
                </div>
              </LocalizedLink>
            </div>
          </div>
        )
      }
    </>
  )
}
