import React from 'react'

import { Icon, InlineNotification } from '@juullabs/react-components'
import { paths } from '@viewlio/config/src/viewlioConfig/paths'
import { useIntl } from 'react-intl'

import { LocalizedLink } from 'components/common/LocalizedLink'

import styles from './Links.module.scss'

export const Links = () => {
  const { formatMessage } = useIntl()

  const buttonProps = {
    label: <LocalizedLink href={paths.globalPrivacyNotices}>
      {formatMessage({ id: 'global_pages.links.view_all' })}
    </LocalizedLink>,
  }

  return (
    <InlineNotification
      buttonProps={buttonProps}
      icon={<Icon name='icon/24x24/protection' />}
      heading={formatMessage({ id: 'global_pages.links.visit_policies' })}
      wrapperClass={styles.wrapper}
    />
  )
}
