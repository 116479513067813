import React from 'react'

import { Footer as ContentfulFooter } from 'components/contentful/Footer'
import { markdown } from 'utils/markdown'

export const Footer = ({ footerEntry }) => (
  <ContentfulFooter contentfulEntry={{
    ...footerEntry,
    rightHandSideContent: markdown(footerEntry.rightHandSideContent),
  }} />
)
